<script setup lang="ts">
const contentKey = 'privacy-policy-agreement'
const { searchContent } = useContent()
const { data: content } = await useAsyncData(contentKey, async () => {
  return await searchContent({ keys: [contentKey] })
})
const slotValue = computed(() => content.value?.[contentKey])
</script>
<template>
  <ContentSlot v-if="slotValue" :content="slotValue" />
</template>
